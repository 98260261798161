export default [
  // Dashboard and Member
  {
    header: 'Dashboard and Member',
    resource: 'User',
  },
  {
    title: 'Dashboard',
    route: 'dashboard-partner',
    icon: 'AirplayIcon',
    resource: 'User',
  },
  {
    title: 'รายการฝาก',
    route: 'deposit',
    icon: 'TrendingUpIcon',
    resource: 'User',
  },
  {
    title: 'รายการถอน',
    route: 'withdraw',
    icon: 'TrendingDownIcon',
    resource: 'User',
  },
  {
    title: 'รายการลูกค้า',
    route: 'member',
    icon: 'UsersIcon',
    resource: 'User',
  },

  // ออกหวย
  {
    header: 'Invite Friend',
    resource: 'User',
  },
  {
    title: 'แนะนำเพื่อน(เซียน)',
    route: 'invite-friend-vip',
    icon: 'UsersIcon',
    resource: 'Agadmin',
  },
  {
    header: 'LOTTO MANAGE',
    resource: 'User',
  },
  {
    title: 'จัดการหวย',
    route: 'manage-lotto-type',
    icon: 'FileTextIcon',
    resource: 'User',
  },
  {
    title: 'อัตราการจ่ายเงิน',
    route: 'rate',
    icon: 'EditIcon',
    resource: 'Agadmin',
  },
  {
    title: 'รอบแทง',
    route: 'manage-time',
    icon: 'ClockIcon',
    resource: 'User',
  },
  {
    title: 'ตั้งสู้',
    route: 'setbet',
    icon: 'SettingsIcon',
    resource: 'Agadmin',
  },
  {
    title: 'ลดหลั่น',
    route: 'descending',
    icon: 'SettingsIcon',
    resource: 'descending',
  },
  {
    title: 'ชวนเพื่อน',
    route: 'invite-friend',
    icon: 'UserPlusIcon',
    resource: 'User',
  },
  {
    title: 'ออกรางวัล',
    route: 'lotto',
    icon: 'ShuffleIcon',
    resource: 'User',
  },
  {
    title: 'ผลการออกรางวัล',
    route: 'pay',
    icon: 'CoffeeIcon',
    resource: 'User',
  },

  // ออกหวย Aggroup
  {
    header: 'LOTTO GROUP MANAGE',
    resource: 'AGgroup',
  },
  {
    title: 'ออกรางวัล',
    route: 'LottoAgent',
    icon: 'ShuffleIcon',
    resource: 'AGgroup',
  },
  {
    header: 'รายงาน',
    resource: 'User',
  },
  {
    title: 'รายการโพย',
    route: 'list-confirm',
    icon: 'FileTextIcon',
    resource: 'User',
  },
  {
    title: 'win/loss',
    route: 'win-loss',
    icon: 'FileTextIcon',
    resource: 'User',
  },
  // SMS Status
  {
    header: 'SMS Status',
    resource: 'User',
  },
  {
    title: 'SMS ทั้งหมด',
    route: 'sms-all',
    icon: 'MailIcon',
    resource: 'User',
  },
  {
    title: 'SMS ฝาก',
    route: 'sms-deposit',
    icon: 'FilePlusIcon',
    resource: 'User',
  },
  {
    title: 'SMS ถอน',
    route: 'sms-withdraw',
    icon: 'FileMinusIcon',
    resource: 'User',
  },
  {
    title: 'True Money Wallet',
    route: 'true-money',
    icon: 'FilePlusIcon',
    resource: 'User',
  },
  {
    title: 'SCB Statement',
    route: 'statement',
    icon: 'FilePlusIcon',
    resource: 'User',
  },
  {
    title: 'KBANK Statement',
    route: 'statement-kbank',
    icon: 'FilePlusIcon',
    resource: 'User',
  },
  {
    title: 'SMS OTP',
    route: 'sms-otp',
    icon: 'MailIcon',
    resource: 'User',
  },

  // Admin Management
  {
    header: 'AG Admin Management',
    resource: 'Agadmin',
  },
  // {
  //   title: 'Reports W/L',
  //   route: 'report',
  //   icon: 'CalendarIcon',
  //   resource: 'Agadmin',
  // },
  {
    title: 'Admin users',
    route: 'admin-management',
    icon: 'UsersIcon',
    resource: 'Agadmin',
  },
  // {
  //   title: 'Promotion',
  //   route: 'promotion',
  //   icon: 'GiftIcon',
  //   resource: 'Admin',
  // },
  {
    title: 'Setting',
    route: 'setting',
    icon: 'SettingsIcon',
    resource: 'Agadmin',
  },
  // {
  //   header: 'Admin Management',
  //   resource: 'Admin',
  // },
  // {
  //   title: 'Agent List',
  //   route: 'agent-list',
  //   icon: 'SettingsIcon',
  //   resource: 'Admin',
  // },

  // Setting
  {
    header: 'Big Admin Management',
    resource: 'Admin',
  },
  {
    title: 'กลุ่มหวย',
    route: 'group',
    icon: 'SettingsIcon',
    resource: 'Admin',
  },
  {
    title: 'ประเภทหวย',
    route: 'type',
    icon: 'SettingsIcon',
    resource: 'Admin',
  },
  {
    title: 'Agent',
    route: 'agent',
    icon: 'SettingsIcon',
    resource: 'Admin',
  },
  {
    title: 'Bank',
    route: 'bank',
    icon: 'CreditCardIcon',
    resource: 'Admin',
  },
  {
    title: 'Channel',
    route: 'channel',
    icon: 'CreditCardIcon',
    resource: 'Admin',
  },
]
